'use strict';

angular.module('informaApp')
	.service('WordHighlightHelper', function() {
		return {
			highlight: function (element, text, options) {
				element.unmark({
					done: function () {
						element.mark(text || '', options);
					}
				});
			}
		};
	});
